import React from 'react';
import Profile from './personal/profile';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    
    <div className="App">
      <header className="App-header">
    <Profile />
      </header>
    </div>
  );
}

export default App;
